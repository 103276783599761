@import "sizing";
#home {
  min-height: 512px;
  .img-home {
    min-height: 400px;
    max-height: 500px;
  }
  @media (max-height: 513px) {
    .img-home {
      max-height: 350px;
    }
  }
  @media (max-height: 769px) {
    .img-home {
      max-height: 450px;
    }
  }
}

.people {
  .person {
    position:absolute;
    z-index:2;
    height: 100px;
    &.person-left {
      bottom: -158px;
      left: 5%;
    }
    &.person-right {
      bottom: -166px;
      right: 5%;
    }
  }
  &.contact {
    .person {
      &.person-right {
        bottom: -146px;
        right: 40%;
      }
    }
  }
}
@media (max-width: 1025px) {
  .people {
    .person {
      &.person-left {
        bottom: -138px;
      }
      &.person-right {
        bottom: -146px;
      }
    }
  }
}

.partner-md img {
  height: 30px;
}
